/* #portal-logo img {
    filter: invert(60%) sepia(75%) saturate(503%) hue-rotate(62deg) brightness(93%) contrast(91%);
}
 */

.template-send-to li#document-action-sendto {
    display: none;
}

#popover-upload > div.items.popover-content {
    max-height: 600px;
    overflow-y: auto;
}
